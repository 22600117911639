import { ControlValueAccessor } from '@angular/forms';

export class AbstractControlValueAccessor<T> implements ControlValueAccessor {
  get controlValue(): T {
    return this._controlValue;
  }

  set controlValue(value: T) {
    if (this.disabled) {
      return;
    }

    // @ts-ignore
    if (typeof this['setValue'] !== 'undefined') {
      // @ts-ignore
      this.setValue(value);
    } else {
      this._controlValue = value;
    }

    this.onTouched();
    this.onChanged(this._controlValue);
  }

  _controlValue: any = null;
  disabled!: boolean;
  onChanged: any = () => {
  };
  onTouched: any = () => {
  };

  writeValue(obj: T): void {
    this.controlValue = obj;
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
