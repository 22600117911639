import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env';
import { map } from 'rxjs/operators';

@Injectable()
export class ApiBase {
  constructor(private http: HttpClient) {
  }

  getCsv(path: string, params: any | HttpParams = new HttpParams()): Observable<any> {
    return this.http
      .get(`${environment.baseUrl}${environment.apiRoute}${path}`, {params, responseType: 'arraybuffer'});
  }
  get(path: string, params: any | HttpParams = new HttpParams()): Observable<any> {
    return this.http
      .get(`${environment.baseUrl}${environment.apiRoute}${path}`, {params});
  }

  put(path: string, body: object = {}): Observable<any> {
    return this.http
      .put(`${environment.baseUrl}${environment.apiRoute}${path}`, body);
  }

  patch(path: string, body: object = {}): Observable<any> {
    return this.http
      .patch(`${environment.baseUrl}${environment.apiRoute}${path}`, body);
  }

  post(path: string, body: object = {}): Observable<any> {
    const httpOptions = {};
    return this.http
      .post(`${environment.baseUrl}${environment.apiRoute}${path}`, body, {...httpOptions, reportProgress: true}).pipe(
        map((resp: any) => {
          return resp.data;
        })
      );
  }

  delete(path: string, params: any | HttpParams = new HttpParams()): Observable<any> {
    return this.http
      .delete(`${environment.baseUrl}${environment.apiRoute}${path}`, {params});
  }

  request(request: HttpRequest<any>): Observable<any> {
    return this.http.request(request);
  }
}
