import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { ApiBase, CoreModule, LoadingOverlayService } from './core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_BASE_HREF } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { WebcamModule} from 'ngx-webcam';
import {AppWrapperComponent} from './pages/app-wrapper/app-wrapper.component';
// import {AppLandingComponent} from './pages/app-landing/app-landing.component';

const PROVIDERS = [
  ApiBase,
  HttpClient,
  LoadingOverlayService
];

@NgModule({
  declarations: [
    AppComponent,
    AppWrapperComponent,
    // AppLandingComponent,
    NotFoundComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedModule,
    CoreModule,
    WebcamModule,
  ],
  providers: [...PROVIDERS],
  bootstrap: [AppComponent]
})
export class AppModule {
}
