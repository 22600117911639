import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractControlValueAccessor } from '@shared/abstracts/control-value-accessor.abstract';

@Component({
  selector: 'app-shared-dropdown',
  templateUrl: './shared-dropdown.component.html',
  styleUrls: ['./shared-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SharedDropdownComponent),
      multi: true
    }
  ]
})
export class SharedDropdownComponent extends AbstractControlValueAccessor<any> implements OnInit {
  @Input() item : any= {};

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

}
