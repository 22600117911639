import {Injectable} from '@angular/core';
import {CanActivate, Router, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {environment} from '@env';

@Injectable({
  providedIn: 'root'
})
export class LockAccessLineGuard implements CanActivate {
  constructor(public router: Router){

  }

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree | any {
    if (environment.isAccessLine) {
      return true;
    }
    this.router.navigate(['', '/']);
    return false;
  }
}
