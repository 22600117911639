import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {NotFoundComponent} from './pages/not-found/not-found.component';
import {CallbackGuard} from '@core/guards/callback.guard';
import {SkipLineGuard} from '@core/guards/skip-line.guard';
import {Unauthenticated} from '@core/guards/unauthenticated.guards';
import { LockAccessLineGuard } from '@core/guards/lock-access-line.guard';


const routes: Routes = [
  {
    path: 'form',
    loadChildren: () => import('../app/pages/app-wrapper/app-wrapper.module').then(m => m.AppWrapperModule),
    canActivate: [LockAccessLineGuard]
  },
  {
    path: '',
    loadChildren: () => import('../app/pages/app-landing/app-landing.module').then(m => m.AppLandingModule),
  },
  // {
  //   path: 'callback',
  //   canActivate: [CallbackGuard],
  //   component: CallbackComponent
  //   // redirectTo: ''
  // },
  {
    path: 'unauthenticated',
    canActivate: [Unauthenticated],
    pathMatch: 'full',
    children: []
  },
  {
    path: 'callback',
    canActivate: [CallbackGuard],
    pathMatch: 'full',
    children: []
  },
  {
    path: 'skip-login-line',
    canActivate: [LockAccessLineGuard, SkipLineGuard],
    children: []
  },
  {
    path: '404',
    component: NotFoundComponent
  },
  {
    path: '**',
    redirectTo: '404'
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
