import { Injectable, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators';
import { ApiBase } from '@core/services/api.service';
import { CredentialsService } from '@core/services/credentials.service';
import { apiEndpoints } from '@config/global-vars';

// Plugins

interface ILoginContext {
  code: string;
  state: string;
}

@Injectable({providedIn: 'root'})
export class AuthenticationService {
  public currentUser: Observable<any>;
  private currentUserSubject: BehaviorSubject<any>;
  private sessionName = 'credentials';

  constructor(
    private apiBase: ApiBase,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private credentialsService: CredentialsService
  ) {
    this.currentUserSubject = new BehaviorSubject<any>(null);
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  public set currentUserValue(value) {
    this.currentUserSubject.next(value);
  }

  readUserData(): any {
    const userData = sessionStorage.getItem(this.sessionName) || '';
    return JSON.parse(userData);
  }

  login(context: ILoginContext): Observable<any> {
    return this.apiBase.get(apiEndpoints.callback, context).pipe(
      tap((user) => {
        if (user) {
          const { data } = user;
          this.currentUserSubject.next(data.user);
          this.credentialsService.setCredentials(
            { access_token: data.token }
          );
        }
      }),

      switchMap((resp) => {
        return this.profile().pipe(
          finalize(() => {
            this.router.navigate(['/']).then(r => r);
          })
        );
      })
    );
  }

  profile(): any {
    return this.apiBase.get(apiEndpoints.profile).pipe(
      map((resp) => {
        this.currentUserSubject.next(resp.data);
        return resp;
      }),
      catchError((err) => {
        return of(false);
      })
    );
  }


  clearAndLogout(): void {
    this.credentialsService.setCredentials();
    this.currentUserSubject.next(null);
    this.router.navigate(['/login']).then(r => r);
  }
}
