import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-app-wrapper',
  templateUrl: './app-wrapper.component.html',
  styleUrls: ['./app-wrapper.component.scss']
})
export class AppWrapperComponent implements OnInit {
  // isLoading = false;
  // isCollapsed = false;
  // currentUser: any;
  // currentLang = 'en';

  constructor(
  ) {
  }

  ngOnInit(): void {}

}
