import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {environment} from '@env';
@Injectable({providedIn: 'root'})

export class Unauthenticated implements CanActivate {
  constructor(
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    window.location.replace(`${environment.baseUrl}/user/auth/redirect`);
    return true;
  }
}
