import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedRadioComponent } from './components/shared-radio/shared-radio.component';
import { SharedDropdownComponent } from './components/shared-dropdown/shared-dropdown.component';
import { SharedCheckboxComponent } from './components/shared-checkbox/shared-checkbox.component';
import { SharedTextareaComponent } from './components/shared-textarea/shared-textarea.component';
import { SharedUploadImagesComponent } from './components/shared-upload-images/shared-upload-images.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { LoaderComponent } from './components/loader/loader.component';
import { NameFileUrlPipe } from './pipes/name-file-url.pipe';
import { ShowErrorsComponent } from './components/show-errors/show-errors.component';
import {CustomDatePipe} from '@shared/pipes/formatDate';
import {WebcamComponent} from '@shared/components/webcam/webcam.component';
import { CameraComponent } from '@shared/components/camera/camera.component';
import { CameraModalComponent } from '@shared/components/camera-modal/camera-modal.component';

const COMPONENT: any[] = [
  SharedRadioComponent,
  SharedDropdownComponent,
  SharedCheckboxComponent,
  SharedTextareaComponent,
  SharedUploadImagesComponent,
  PageHeaderComponent,
  ModalComponent,
  LoaderComponent,
  ShowErrorsComponent,
  WebcamComponent,
  CameraComponent,
  CameraModalComponent
];

const PIPE: any [] = [
  NameFileUrlPipe,
  CustomDatePipe
];

@NgModule({
  declarations: [...COMPONENT, ...PIPE],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    ...COMPONENT,
    ...PIPE
  ]
})

export class SharedModule {
}
