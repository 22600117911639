import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-show-errors',
  templateUrl: './show-errors.component.html',
  styleUrls: ['./show-errors.component.scss']
})
export class ShowErrorsComponent implements OnInit {
  @Input() ctrl!: FormControl;

  ERROR_MESSAGE: any = {
    required: () => `入力内容に誤りがあります。`,
    pattern: () => `入力内容に誤りがあります。`,
    required_custom: (text: string) => `${text}`,
    maxlength: (par: any) => `${par.requiredLength}文字以内でご入力ください。`,
    minlength: (par: any) => `${par.requiredLength  == '10' ? '11' : par.requiredLength}文字以内でご入力ください。`,
    incorrect: (message: any) => `${message}`
  };

  constructor() {
  }

  ngOnInit(): void {
  }

  shouldShowErrors(): boolean | any {
    return this.ctrl && this.ctrl.errors && this.ctrl.touched;
  }

  listOfErrors(): string[] {
    return Object.keys((this.ctrl?.errors as any)).map(
      err => {
        if(err !== 'message') {
          if(['incorrect'].includes(err)) {
            return this.ERROR_MESSAGE['incorrect'](this.ctrl?.getError('message'))
          }else {
            return this.ERROR_MESSAGE[err](this.ctrl?.getError(err))
          }
        }
      }
    );
  }
}
