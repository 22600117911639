import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nameFileUrl'
})
export class NameFileUrlPipe implements PipeTransform {

  transform(value: string[], ...args: unknown[]): unknown {
    if (value) {
      return value[0]?.split('/').pop();
    }
    return false;
  }
}
