<div class="box">
  <app-page-header
    [required]="item?.is_required"
    [title]="item?.question"
  ></app-page-header>

  <p class="info" *ngIf="!item?.is_required">※web領収書などスクリーンショットが1枚の画像に収まらない場合は、<br class="br-pc">分割して2枚目をこちらからアップロードしてください。</p>

  <!--Custom file upload-->
  <div class="file-upload">
    <input type="file" class="rq" accept=".png, .jpg, .gif"
           (change)="upload($event, 'uv0'+ item.id)"
           name="image02" id="images02" />
    <a href="#" class="btn-file-upload">
      ファイルを選択
    </a>
  </div>
  <div class="file-uploadValue">
    <input type="text" disabled class="uploadValue" [id]="'uv0' + item.id" value="選択されていません。"/>
  </div>
<!--  <input type="text" disabled class="uploadValue" [id]="'uv0' + item.id" value="選択されていません。"/>-->
<!--  <label class="file">-->
<!--    <input type="file" class="rq"-->
<!--           accept=".png, .jpg, .gif"-->
<!--           (change)="upload($event, 'uv0'+ item.id)"-->
<!--           name="image02" id="images02">-->
<!--    &lt;!&ndash;    <input type="file" class="rq" (change)="upload($event)"  name="image02" id="images02" multiple>&ndash;&gt;-->
<!--    <input type="text" disabled class="uploadValue" [id]="'uv0' + item.id" value="選択されていません。"/>-->
<!--  </label>-->
  <ng-content select="[pageRequired]"></ng-content>
  <input type="hidden" name="image_temp" value=""/>
  <input type="hidden" name="uploadFile" value=""/>
  <div [innerHTML]="item?.options.description_html">
<!--    <picture>-->
<!--      <video style="display: none;">-->
<!--        <source media="(min-width:781px)" srcset="assets/images/recipt.png 1x,assets/images/recipt@2x.png 2x">-->
<!--        <source media="(max-width:780px)" srcset="assets/spimages/recipt.png">-->
<!--      </video>-->
<!--      <img src="assets/images/recipt.png" alt="レシート撮影時の注意" class="switching">-->
<!--    </picture>-->
  </div>

  <!-- <p class="indent">※画像の切れや汚れ、ピンボケ等により内容判断ができない場合は応募が無効となります。</p>
  <p class="indent">※対応画像形式：jpg ／ png ／ gif</p>
  <p class="indent">※ファイル容量上限：5MB</p>
  <p class="indent">※レシートを他人へ譲渡することは固く禁止させていただきます。予めご了承くださいませ。</p>
  <p class="indent">※同一レシートを使用した複数回のご応募は無効となります。</p> -->

</div>
