import { Component, forwardRef, Inject, Injector, Input, OnInit, Optional, Self } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractControlValueAccessor } from '@shared/abstracts/control-value-accessor.abstract';

@Component({
  selector: 'app-shared-textarea',
  templateUrl: './shared-textarea.component.html',
  styleUrls: ['./shared-textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SharedTextareaComponent),
      multi: true
    }
  ]
})
export class SharedTextareaComponent extends AbstractControlValueAccessor<any> implements OnInit {
  @Input() item: any;

  constructor() {
    super();
  }
  ngOnInit(): void {
  }

}
