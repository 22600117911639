import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { format  } from 'date-fns';
import { ja } from 'date-fns/locale';
import { formatInTimeZone } from 'date-fns-tz';
@Pipe({
  name: 'customDate'
})
export class CustomDatePipe extends
  DatePipe implements PipeTransform {
  transform(value: any, formatType: string = 'date'): any {
    if (value) {
      const date = new Date(value);

      return formatInTimeZone(date, 'Asia/Tokyo', 'yyyy.MM.dd HH:mm', { locale: ja });
    }

    return value;
  }
}
