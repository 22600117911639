import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {FormService} from '@core/services/form.service';
import {catchError, finalize, map, switchMap} from 'rxjs/operators';
import {AuthenticationService} from '@core/services/authentication.service';
import {ApiBase} from '@core/services';
import {apiEndpoints} from '@config/global-vars';
import {CredentialsService} from '@core/services/credentials.service';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CallbackGuard implements CanActivate {
  constructor(
    public authService: AuthenticationService,
    public router: Router,
    public apiBase: ApiBase,
    private credentialsService: CredentialsService
  ) {

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree | any {

    const params = {
      code: route.queryParams.code,
      state: route.queryParams.state
    };

    return this.apiBase.get(apiEndpoints.callback, params).pipe(
      catchError((error) => {
        if (error.error.status === 403) {
          // show modal error here
          window.location.replace(environment.urlAddFriend);
          return error;
        }
        if (error.error.status === 422) {
          this.router.navigate(['']);
        }
        return error;
      }),

      switchMap((resp: any) => {
        if (resp?.data.token) {
          this.credentialsService.setCredentials(
            {access_token: resp?.data.token}
          );
        }

        return this.apiBase.get(apiEndpoints.profile).pipe(
          map(({data}) => {
            if (data.is_finished_survey) {
              this.router.navigate(['form', 'my-page']);
            } else {
              this.router.navigate(['form', 'survey']);
            }
            return data;
          })
        );
      })
    );
  }

}
