<div class="modal-wrapper" [style]="modalService.isOpenModal ? 'opacity: 1; visibility: visible;' : 'opacity: 0;visibility: hidden;'">
  <a (click)="onClose()" class="modal-overlay"></a>

  <div class="modal-window">
    <div class="modal-content">
      <a (click)="onClose()" class="modal-close"></a>
        <h4 [innerHTML]="mess" *ngFor="let mess of (modalService.message$ | async)">
          {{ mess }}
        </h4>
      <div _ngcontent-aud-c68="" class="ac_flex">
        <div _ngcontent-aud-c68="" class="ac_bx02">
          <a _ngcontent-aud-c68="" (click)="onClose()">
            <img _ngcontent-aud-c68="" src="./assets/img/btn_md_back.svg" alt="戻る">
          </a>
        </div>
      </div>

    </div>
  </div>
</div>
