import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

// Core Module
import { Router } from '@angular/router';
import { ModalService } from '@core/services';
import {CredentialsService} from '@core/services/credentials.service';
import {AuthenticationService} from '@core/services/authentication.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    public router: Router,
    public modalService: ModalService,
    public credentialsService: CredentialsService,
    public authService: AuthenticationService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const url = request?.url?.split('/')[request.url.split('/').length - 1];
    return next.handle(request).pipe(
      catchError((resp) => {
        if (resp.status === 0 || !navigator.onLine) {
          this.modalService.isOpenModal = true;
          this.modalService.message$.next(['サーバに接続できません。\n' +
          'ネットワーク状態を確認して、再度お試しください。']);
        } else if (resp.error.status === 422) {
          this.modalService.isOpenModal = true;
          this.modalService.message$.next(resp.error.errors[0].message);
        } else if (resp.error.status === 400 && url !== 'regist-point') {
          this.modalService.isOpenModal = true;
          this.modalService.message$.next([resp.error.error.message]);
        } else if (resp.error.status === 429) {
          this.modalService.isOpenModal = true;
          this.modalService.message$.next(['１日あたりのポイント申請回数は10回までとなります。', '明日以降に申請をお願いいたします。']);
        }
        else if (resp.error.status === 401) {
          this.credentialsService.setCredentials();
          this.authService.currentUserValue = null;
          this.router.navigate(['', '/']);
        }
        return throwError(resp);
      })
    );
  }
}
