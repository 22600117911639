import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {CredentialsService} from '@core/services/credentials.service';
import {catchError, debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {Router} from '@angular/router';
import {environment} from '@env';

// Core Module

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private credentialsService: CredentialsService,
    private router: Router,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const credentials = this.credentialsService.credentials;
    // add authorization header with jwt token if available
    const token = credentials?.access_token;
    if (token && request.url.includes('/api')) {
      request = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + token
        }
      });
    }
    // @ts-ignore
    return next.handle(request).pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      catchError((err: HttpErrorResponse) => {
        switch (err.status) {
          case 401:
            this.credentialsService.setCredentials();
            this.router.navigate(['', '/']);
            break;
          case 404:
            this.router.navigate(['', '404']);
            break;
          case 422:
            break;
          case 403:
            window.location.replace(environment.urlAddFriend);
            break;
        }
        return throwError(err);
      })
    );
  }
}
