<!-- Modal Content (The Camera) -->
<div class="modal-content-camera" (window:resize)="onResize($event)">
  <app-camera
    class="camera-container"
    #camera
    [imageType]="'image/png'"
    [imageQuality]="1"
    [width]="width"
    [height]="height"
    [videoOptions]="videoOptions"
    [trigger]="triggerObservable"
    [switchCamera]="nextCameraObservable"
    [allowCameraSwitch]="allowCameraSwitch"
    (cameraSwitched)="cameraWasSwitched($event)"
    (imageCapture)="handleImage($event)"
    (initError)="handleInitError($event)"
  ></app-camera>

  <div class="btn bl btn-snapshot" style="width: 160px; height: 60px">
    <p>撮影する</p><a (click)="triggerSnapshot()"></a>
  </div>
</div>
