import { Component } from '@angular/core';
import {ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import {LoadingOverlayService, ModalService} from '@core/services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'taiyou-user-angular';
  isPageNotFound = false;

  constructor(
    public router: Router,
    public loadingOverlayService: LoadingOverlayService,
    public modalService: ModalService,
    private _route: ActivatedRoute
  ) {
    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationStart)
      )
      .subscribe(() => {
        this.loadingOverlayService.isLoading = true;
      });

    this.router.events
      .pipe(
        filter(
          (e) =>
            e instanceof NavigationEnd ||
            e instanceof NavigationCancel ||
            e instanceof NavigationError
        )
      )
      .subscribe((event: any) => {
        this.loadingOverlayService.isLoading = false;
        this.checkLayout();
        window.scrollTo(0, 0);
        event.urlAfterRedirects === '/404' ? this.isPageNotFound = true : this.isPageNotFound = false;
      });
  }

  checkLayout(): void {
    if (this._route.firstChild?.snapshot?.routeConfig?.path === '') {
      document.getElementsByTagName('body')[0].style.backgroundColor = '#77C0D8';
    } else {
      document.getElementsByTagName('body')[0].style.backgroundColor = '#fff';
    }

  }
}
