import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractControlValueAccessor } from '@shared/abstracts/control-value-accessor.abstract';
import * as $ from 'jquery';

@Component({
  selector: 'app-shared-upload-images',
  templateUrl: './shared-upload-images.component.html',
  styleUrls: ['./shared-upload-images.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SharedUploadImagesComponent),
      multi: true
    }
  ]
})
export class SharedUploadImagesComponent extends AbstractControlValueAccessor<any> {
  @Input() item: any;

  constructor() {
    super();
  }

  upload(event: Event, id: string) {
    const target = event.target as HTMLInputElement;
    const files = target.files as FileList;
    const $id = $('#' + id);

    if(files.length) {
      const fileSizeInKB = Math.round(files[0]?.size / 1024 / 1024);
      const fileType = files[0].type;
      $id.val(files[0]?.name);
      (['image/gif', 'image/jpeg', 'image/png'].includes(fileType) && fileSizeInKB <= 5) ? $id.removeAttr('hidden') : $id.attr('hidden', 'true');
      this.writeValue(files[0]);
    }else {
      $id.val('');
      $id.attr('hidden', 'true');
      this.writeValue('');
    }
  }


}
