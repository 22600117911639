import {AfterViewInit, Component, forwardRef, Input, OnInit} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {AbstractControlValueAccessor} from '@shared/abstracts/control-value-accessor.abstract';

@Component({
  selector: 'app-shared-checkbox',
  templateUrl: './shared-checkbox.component.html',
  styleUrls: ['./shared-checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SharedCheckboxComponent),
      multi: true
    }
  ]
})
export class SharedCheckboxComponent extends AbstractControlValueAccessor<any> implements OnInit {
  selectedValue: any[] = [];
  @Input() item: any = {};

  constructor() {
    super();
  }

  ngOnInit(): void {

  }

  changeCheckbox(value: any) {
    const indexValue = this.selectedValue.findIndex(s => s == value);
    if (indexValue > -1) {
      this.selectedValue.splice(indexValue, 1);
    } else {
      this.selectedValue.push(value);
    }
    this.writeValue(this.selectedValue);
  }

  isChecked(value: any) {
    if (this.controlValue) {
      this.selectedValue = this.controlValue;
      return this.controlValue?.findIndex((v: any) => v === value) > -1;
    }
    return false;
  }
}
