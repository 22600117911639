<div class="box">
  <app-page-header
    [required]="item?.is_required"
    [title]="item?.question"
  ></app-page-header>
  <label>
    <select name="age" id="pref" [(ngModel)]="controlValue">
      <option [value]="''">選択してください。</option>
      <option *ngFor="let ans of item?.answers" [value]="ans">{{ans}}</option>
    </select>
  </label>
  <ng-content select="[pageRequired]"></ng-content>
</div>
