<!--<div class="kv">-->
<!--  <picture>-->
<!--    <source media="(min-width:781px)" srcset="assets/images/kv.png 1x,assets/images/kv@2x.png 2x">-->
<!--    <source media="(max-width:780px)" srcset="assets/images/kv.png">-->
<!--    <img src="assets/images/kv.png" alt="キモチONだ！WONDERFULチャレンジ！" class="switching">-->
<!--  </picture>-->
<!--</div>-->
<div class="page-not-found">
  <h2>404</h2>
  <h4>NO PAGE FOUND</h4>
  <p>お探しのページは見つかりませんでした。</p>
</div>
