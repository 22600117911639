import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  EventEmitter,
  OnDestroy,
  Output
} from '@angular/core';

@Component({
  selector: 'app-webcam',
  templateUrl: './webcam.component.html',
  styleUrls: ['./webcam.component.scss']
})
export class WebcamComponent implements OnInit, OnDestroy {
  @ViewChild('videoElement', {static: true}) videoElementRef!: ElementRef<HTMLVideoElement>;
  @ViewChild('canvas', {static: true}) canvasRef!: ElementRef<HTMLCanvasElement>;
  @Output() triggerSnapshot$ = new EventEmitter<string>();
  @Output() errors$ = new EventEmitter<string[]>();

  private videoElement!: HTMLVideoElement;
  private canvas!: HTMLCanvasElement;
  private canvasContext: CanvasRenderingContext2D | null;
  private constraints = {
    audio: false,
    video: {facingMode: 'environment'}
  };
  private stream!: MediaStream;
  capturedImageSrc: string;
  errorMsg: string[];

  constructor() {
    this.canvasContext = null;
    this.capturedImageSrc = '';
    this.errorMsg = [];
  }

  ngOnInit(): void {
    this.videoElement = this.videoElementRef.nativeElement;
    this.canvas = this.canvasRef.nativeElement;
    this.canvasContext = this.canvas.getContext('2d');
    this.init();
  }

  handleSuccess = (stream: MediaStream): void => {
    (window as any).stream = stream;
    this.videoElement.srcObject = stream;

    this.videoElement.addEventListener('loadedmetadata', () => {
      this.videoElement.play().then(() => {
        this.captureFrame();
      });
    });
  }

  captureFrame = (): void => {
    if (!(this.videoElement.srcObject as MediaStream).active) {
      this.errorMsg.push('カメラへのアクセスを許可する必要があります。');
      this.errors$.emit(this.errorMsg);
      return;
    }

    if (this.canvasContext) {
      this.canvas.width = this.videoElement.videoWidth;
      this.canvas.height = this.videoElement.videoHeight;
      this.canvasContext.drawImage(this.videoElement, 0, 0, this.canvas.width, this.canvas.height);
      requestAnimationFrame(this.captureFrame);
    }
  }

  handleError = (error: MediaStreamError): void => {
    this.errorMsg = [];

    if (error.name === 'OverconstrainedError') {
      this.errorMsg.push(`カメラ機能がこの端末でサポートされていません。`);
    } else if (error.name === 'NotAllowedError') {
      this.errorMsg.push('カメラへのアクセスを許可する必要があります。');
    } else {
      this.errorMsg.push(`getUserMedia error: ${error.name}`);
    }

    this.errors$.emit(this.errorMsg);
  }

  async init(): Promise<void> {
    try {
      this.stream = await navigator.mediaDevices.getUserMedia(this.constraints);
      this.handleSuccess(this.stream);
    } catch (e) {
      this.handleError(e);
    }
  }

  handleClickCaptureButton(): void {
    if (this.canvasContext) {
      this.canvas.width = this.videoElement.videoWidth;
      this.canvas.height = this.videoElement.videoHeight;
      this.canvasContext.drawImage(this.videoElement, 0, 0, this.canvas.width, this.canvas.height);
      this.capturedImageSrc = this.canvas.toDataURL('image/png');

      this.triggerSnapshot$.emit(this.capturedImageSrc);
    }
  }

  ngOnDestroy(): void {
    if (this.stream) {
      this.stream.getTracks().forEach(track => track.stop());
    }

    this.canvasContext = null;
    this.capturedImageSrc = '';
    this.errorMsg = [];
  }
}
