interface Endpoint {
  callback: string;
  redirect: string;
  profile: string;
  apply: string;
  skip: string;
  questions: string;
  survey: string;
  registPoint: string;
  confirmPoint: string;
  approve: string;
  histories: string;
  course: string;
  instant: string;
  [key: string]: string;
}

export const apiEndpoints: Endpoint = {
  skip: 'auth/skip-login',
  callback: 'auth/callback',
  redirect: 'auth/redirect',
  profile: 'my-page/profile',
  apply: 'my-page/applies',
  survey: 'my-page/input-survey',
  questions: 'questions',
  registPoint: 'my-page/regist-point',
  confirmPoint: 'my-page/confirm-point',
  approve: 'my-page/request-point',
  histories: 'my-page/histories',
  course: 'my-page/tradition-apply',
  instant: 'my-page/instant-apply',
  delivery: 'my-page/input-delivery',
  extraGift: 'my-page/extra-gift-apply',
  wInstant: 'my-page/instant-extra-gift-apply',
  instantGiftApply: 'my-page/instant-gift-apply'
};

