<div class="box02">
  <app-page-header
    [required]="item?.is_required"
    [title]="item?.question"
  ></app-page-header>
  <div class="box_inner_lt">
    <div class="box_inner_bx" *ngFor="let ans of item?.answers">
      <label>
        <p><input (change)="changeCheckbox(ans)" [value]="ans" class="checkbox-input" name="q2"
                  [checked]="isChecked(ans)"
                  type="checkbox"><span></span></p>
        <div>{{ans}}</div>
        <p></p>
      </label>
    </div>
  </div>
  <ng-content select="[pageRequired]"></ng-content>
</div>
