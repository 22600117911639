<div class="box">
  <app-page-header
    [required]="item?.is_required"
    [title]="item?.question"
  ></app-page-header>

  <div class="box_inner">
    <label>
      <p><input type="radio" name="sex" [value]="'男性'" class="w5em" [(ngModel)]="controlValue"><span
        class="radio_sex">男性</span>
      </p>
    </label>
    <label>
      <p><input type="radio" name="sex" [value]="'女性'" class="w5em" [(ngModel)]="controlValue"><span
        class="radio_sex">女性</span>
      </p>
    </label>
    <label>
      <p><input type="radio" name="sex" [value]="'未回答'" class="w5em" [(ngModel)]="controlValue"><span
        class="radio_sex">未回答</span>
      </p>
    </label>
  </div>
  <ng-content select="[pageRequired]"></ng-content>
</div>
