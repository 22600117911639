import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalService } from '@core/services/modal.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  @Input() isShow = false;
  @Output() onSubmit$ = new EventEmitter<any>();
  @Output() onClose$ = new EventEmitter<any>();

  constructor(
    public modalService: ModalService,
    public router: Router
  ) {
  }

  ngOnInit(): void {
  }

  onClose(): void {
    this.modalService.isOpenModal = false;
    if((window.location.pathname.includes('confirm-delivery') && this.modalService.message == '配送先が既に登録されております。')
      || this.modalService.message == "現在のポイント数が不足していますので<br>ご応募いただけません。"
      || this.modalService.message == "キャンペーンは終了しました。<br>ご参加ありがとうございました。"
      ){
      this.router.navigate(['form', 'my-page']);
    }

    if(this.modalService.message == '正常に処理できませんでした。<br>しばらく経ってからもう一度お試しください。'){
      this.router.navigate(['', '/']);
    }
  }

}
