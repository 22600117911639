import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  public isOpenModal$: Observable<boolean>;
  public message$ = new BehaviorSubject<any>([]);

  private isOpenModalSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {
    this.isOpenModal$ = this.isOpenModalSubject.asObservable();
  }

  set isOpenModal(value: boolean) {
    this.isOpenModalSubject.next(value);
  }

  get isOpenModal(): boolean {
    return this.isOpenModalSubject.value;
  }

  get message(): any {
    return this.message$.value;
  }

  set message(value: any) {
    this.message$.next(value);
  }
}
